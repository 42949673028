<div class="main-header">
    <div class="logo">
        <img src="./assets/images/logo.png"  alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div class="d-flex align-items-center">
        <!-- Mega menu -->
        <div ngbDropdown class="mega-menu d-none d-md-block">
           
            <div ngbDropdownMenu perfectScrollbar>
                <div class="row m-0">
                    <div class="col-md-4 p-4 bg-img">
                        <h2 class="title">Mega Menu <br> Sidebar</h2>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores natus
                            laboriosam fugit, consequatur.
                        </p>
                        <p class="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                            Exercitationem odio amet eos dolore suscipit placeat.</p>
                        <button class="btn btn-lg btn-rounded btn-outline-warning">Learn More</button>
                    </div>
                    <div class="col-md-4 p-4">
                        <p class="text-primary text--cap border-bottom-primary d-inline-block">Features</p>
                        <div class="menu-icon-grid w-auto p-0">
                            <a href="#"><i class="i-Shop-4"></i> Home</a>
                            <a href="#"><i class="i-Library"></i> UI Kits</a>
                            <a href="#"><i class="i-Drop"></i> Apps</a>
                            <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                            <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                            <a href="#"><i class="i-Ambulance"></i> Support</a>
                        </div>
                    </div>
                    <div class="col-md-4 p-4">
                        <p class="text-primary text--cap border-bottom-primary d-inline-block">Components</p>
                        <ul class="links">
                            <li><a href="accordion.html">Accordion</a></li>
                            <li><a href="alerts.html">Alerts</a></li>
                            <li><a href="buttons.html">Buttons</a></li>
                            <li><a href="badges.html">Badges</a></li>
                            <li><a href="carousel.html">Carousels</a></li>
                            <li><a href="lists.html">Lists</a></li>
                            <li><a href="popover.html">Popover</a></li>
                            <li><a href="tables.html">Tables</a></li>
                            <li><a href="datatables.html">Datatables</a></li>
                            <li><a href="modals.html">Modals</a></li>
                            <li><a href="nouislider.html">Sliders</a></li>
                            <li><a href="tabs.html">Tabs</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Search bar -->
       
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">
       
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
        <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
            <i ngbDropdownToggle class="i-Data-Settings text-muted header-icon" role="button"></i>
            <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                <div class="menu-icon-grid">
                    <a [routerLink]="['/settings/user']"><i class="i-MaleFemale"></i>ข้อมูลผู้ใช้งานระบบ</a>
                    <a [routerLink]="['/settings/permission']"><i class="i-Data-Shield"></i>สิทธิ์การเข้าใช้งาน</a>
                    <a [routerLink]="['/settings/project']"><i class="i-Building"></i>ตั้งค่าข้อมูลโครงการ</a>
                    <a href="#"><i class="i-Big-Data"></i>ตั้งค่าข้อมูลอื่นๆ</a>
                   
                </div>
            </div>
        </div>
     
        <div  ngbDropdown [placement]="'bottom-right'">
            <div class="badge-top-container ">
         
                <i ngbDropdownToggle class="i-Bell text-muted header-icon " role="button"></i>
                <span class="badge light text-white bg-primary rounded-circle">{{notifications.length}}</span>
            </div>
            <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">
                    <div class="notification-icon">
                        <i class="{{item.icon}} text-{{item.status}} me-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <p class="m-0 d-flex align-items-center">
                            <span>{{item.title}}</span>
                            <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ms-1 me-1">{{item.badge}}</span>
                            <span class="flex-grow-1"></span>
                            <span class="text-small text-muted ms-auto">{{item.time | relativeTime}}</span>
                        </p>
                        <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div ngbDropdown [placement]="'bottom-right'" role="button" class="user col align-self-end ">
            <img  src="./assets/images/faces/1.png" id="userDropdown" ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                    <i class="i-Lock-User me-2"></i> ถาวร ประเสริฐแก้ว
                </div>
                <button class="dropdown-item">ข้อมูลโปรไฟล์</button>
               
                <button class="dropdown-item" (click)="signout()">ออกจากระบบ</button>
            </div>
        </div>

    </div>

</div>